var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import user from './user';
import recommendation from './recommend';
import anime from './anime';
import place from './place';
export default __assign(__assign(__assign(__assign({}, user), recommendation), anime), place);
