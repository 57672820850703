import { request } from './instance';
var getAnimePlaces = function (id) {
    return request.get("/place/?anime_id=".concat(id));
};
var getPlacePage = function (page, pageSize) {
    return request.get("/place/", {
        params: {
            page: page,
            page_size: pageSize,
        },
    });
};
var getPlace = function (id) {
    return request.get("/place/".concat(id));
};
var checkPlaceCollection = function (id) {
    return request.get("/place/".concat(id, "/collection/"));
};
var addPlaceCollection = function (id) {
    return request.post("/place/".concat(id, "/collection/"));
};
var deletePlaceCollection = function (id) {
    return request.delete("/place/".concat(id, "/collection/"));
};
export default {
    getAnimePlaces: getAnimePlaces,
    getPlacePage: getPlacePage,
    getPlace: getPlace,
    checkPlaceCollection: checkPlaceCollection,
    addPlaceCollection: addPlaceCollection,
    deletePlaceCollection: deletePlaceCollection,
};
