import { request } from './instance';
var getAnimePage = function (page, pageSize, search, ordering, ascending) {
    if (ascending === void 0) { ascending = true; }
    return request.get('/anime/', {
        params: {
            page: page,
            page_size: pageSize,
            ordering: ascending ? ordering : "-".concat(ordering),
            search: search,
        },
    });
};
var getAnimeDetail = function (id) {
    return request.get("/anime/".concat(id));
};
var checkAnimeCollection = function (id) {
    return request.get("/anime/".concat(id, "/collection/"));
};
var addAnimeCollection = function (id) {
    return request.post("/anime/".concat(id, "/collection/"));
};
var deleteAnimeCollection = function (id) {
    return request.delete("/anime/".concat(id, "/collection/"));
};
export default { getAnimeDetail: getAnimeDetail, getAnimePage: getAnimePage, addAnimeCollection: addAnimeCollection, checkAnimeCollection: checkAnimeCollection, deleteAnimeCollection: deleteAnimeCollection };
