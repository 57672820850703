import React, { useEffect, useMemo } from 'react';
import Request from '@/request';
import PageLoading from '@/components/PageLoading';
import AuthContext from './context';
var AuthContainer = function (_a) {
    var children = _a.children;
    var _b = React.useState(null), userInfo = _b[0], setUserInfo = _b[1];
    var _c = React.useState(true), isLoading = _c[0], setIsLoading = _c[1];
    var refreshInfo = function () {
        Request.getUserInfo()
            .then(function (res) {
            console.log('首次加载', res);
            setUserInfo(res.data);
        })
            .catch(function (err) {
            console.log('首次加载失败', err);
        })
            .finally(function () {
            setIsLoading(false);
        });
    };
    var login = function (form) {
        return new Promise(function (resolve, reject) {
            Request.login(form)
                .then(function (res) {
                localStorage.setItem('access', res.data.access);
                localStorage.setItem('refresh', res.data.refresh);
                refreshInfo();
                resolve(res);
            })
                .catch(function (err) {
                reject(err);
            });
        });
    };
    var register = function (form) {
        return new Promise(function (resolve, reject) {
            Request.register(form)
                .then(function (res) {
                localStorage.setItem('access', res.data.access);
                localStorage.setItem('refresh', res.data.refresh);
                refreshInfo();
                resolve(res);
            })
                .catch(function (err) {
                reject(err);
            });
        });
    };
    var logout = function () {
        // 后端暂无手动清除 token 接口，因此登出时直接清空 token
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
        setUserInfo(null);
    };
    useEffect(function () {
        // 首次进入后获取用户信息
        refreshInfo();
    }, []);
    var value = useMemo(function () {
        // console.log('userInfo改变了', userInfo);
        return {
            userInfo: userInfo,
            refreshInfo: refreshInfo,
            login: login,
            register: register,
            logout: logout,
        };
    }, [userInfo]);
    return isLoading ? React.createElement(PageLoading, null) : React.createElement(AuthContext.Provider, { value: value }, children);
};
var useAuth = function () {
    var context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within a AuthProvider');
    }
    return context;
};
export default AuthContainer;
export { useAuth };
