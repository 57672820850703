import React, { Suspense, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import useRoutingInstrumentation from 'react-router-v6-instrumentation';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { sentryDsn } from './secret';
import AuthContainer from './context/AuthContainer';
import PageLoading from './components/PageLoading';
import './App.less';
import './assets/iconfont/iconfont.css';
import './assets/customStyle/antd.less';
var Home = React.lazy(function () { return import(/* webpackChunkName: "home" */ './views/Home'); });
var Work = React.lazy(function () { return import(/* webpackChunkName: "work" */ './views/Work'); });
var Details = React.lazy(function () { return import(/* webpackChunkName: "details" */ './views/Details'); });
var Login = React.lazy(function () { return import(/* webpackChunkName: "login" */ './views/Login'); });
var Mine = React.lazy(function () { return import(/* webpackChunkName: "mine" */ './views/Mine'); });
var Places = React.lazy(function () { return import(/* webpackChunkName: "places" */ './views/Places'); });
var Search = React.lazy(function () { return import(/* webpackChunkName: "search" */ './views/Search'); });
var App = function () {
    var routingInstrumentation = useRoutingInstrumentation();
    useEffect(function () {
        if (process.env.NODE_ENV === 'production') {
            Sentry.init({
                dsn: sentryDsn,
                integrations: [
                    new Integrations.BrowserTracing({
                        routingInstrumentation: routingInstrumentation,
                    }),
                ],
                // Set tracesSampleRate to 1.0 to capture 100%
                // of transactions for performance monitoring.
                // We recommend adjusting this value in production
                tracesSampleRate: 0.6,
            });
        }
    }, [routingInstrumentation]);
    return (React.createElement("div", null,
        React.createElement(AuthContainer, null,
            React.createElement(Routes, null,
                React.createElement(Route, { path: "/:page", element: React.createElement(Suspense, { fallback: React.createElement(PageLoading, null) },
                        React.createElement(Home, null)) }),
                React.createElement(Route, { path: "/", element: React.createElement(Navigate, { to: "/1" }) }),
                React.createElement(Route, { path: "/login", element: React.createElement(Suspense, { fallback: React.createElement(PageLoading, null) },
                        React.createElement(Login, null)) }),
                React.createElement(Route, { path: "/mine/*", element: React.createElement(Suspense, { fallback: React.createElement(PageLoading, null) },
                        React.createElement(Mine, null)) }),
                React.createElement(Route, { path: "/work/:page/:ordering/:ascending", element: React.createElement(Suspense, { fallback: React.createElement(PageLoading, null) },
                        React.createElement(Work, null)) }),
                React.createElement(Route, { path: "/work", element: React.createElement(Navigate, { to: "/work/1/id/true" }) }),
                React.createElement(Route, { path: "/detail/:id", element: React.createElement(Suspense, { fallback: React.createElement(PageLoading, null) },
                        React.createElement(Details, null)) }),
                React.createElement(Route, { path: "/search/:key/:page", element: React.createElement(Suspense, { fallback: React.createElement(PageLoading, null) },
                        React.createElement(Search, null)) }),
                React.createElement(Route, { path: "/search/:key", element: React.createElement(Suspense, { fallback: React.createElement(PageLoading, null) },
                        React.createElement(Search, null)) }),
                React.createElement(Route, { path: "/places", element: React.createElement(Suspense, { fallback: React.createElement(PageLoading, null) },
                        React.createElement(Places, null)) }),
                React.createElement(Route, { path: "/places/:id", element: React.createElement(Suspense, { fallback: React.createElement(PageLoading, null) },
                        React.createElement(Places, null)) })))));
};
export default App;
