import { request } from './instance';
import md5 from 'blueimp-md5';
var login = function (form) {
    return request.post('/user/login/', {
        username: form.username,
        password: md5(form.password),
    });
};
var register = function (form) {
    return request.post('/user/register/', {
        username: form.username,
        nickname: form.nickname,
        password: md5(form.password),
        password2: md5(form.confirmPassword),
        mobile: form.mobile,
        sms_code: form.sms,
        allow: form.allow.toString(),
    });
};
var getUsernameCount = function (username) {
    return request.get("/user/users/".concat(username, "/count/"));
};
var getMobileCount = function (mobile) {
    return request.get("/user/mobiles/".concat(mobile, "/count/"));
};
var getSmsCode = function (mobile) {
    return request.get("/smscodes/".concat(mobile, "/"));
};
var getUserInfo = function () {
    return request.get('/user/');
};
var refresh = function () {
    request
        .post('/user/refresh/', {
        refresh: localStorage.getItem('refresh'),
    })
        .then(function (res) {
        localStorage.setItem('access', res.data.access);
    })
        .catch(function (err) {
        console.log(err);
        // 如果刷新失败，则重新登录
    });
};
var getUserAnimeCollection = function () {
    return request.get('/user/anime/');
};
var getUserPlaceCollection = function () {
    return request.get('/user/place/');
};
var checkAnimeCollection = function (id) {
    return request.get("/anime/".concat(id, "/collection/"));
};
var addAnimeCollection = function (id) {
    return request.post("/anime/".concat(id, "/collection/"));
};
var deleteAnimeCollection = function (id) {
    return request.delete("/anime/".concat(id, "/collection/"));
};
var changeAvatar = function (avatar) {
    var formData = new FormData();
    formData.append('avatar', avatar);
    return request.put('/user/', formData);
};
var changeNickname = function (nickname) {
    var formData = new FormData();
    formData.append('nickname', nickname);
    return request.put('/user/', formData);
};
export default {
    login: login,
    getUserInfo: getUserInfo,
    refresh: refresh,
    register: register,
    getUsernameCount: getUsernameCount,
    getMobileCount: getMobileCount,
    getSmsCode: getSmsCode,
    getUserAnimeCollection: getUserAnimeCollection,
    getUserPlaceCollection: getUserPlaceCollection,
    checkAnimeCollection: checkAnimeCollection,
    addAnimeCollection: addAnimeCollection,
    deleteAnimeCollection: deleteAnimeCollection,
    changeAvatar: changeAvatar,
    changeNickname: changeNickname,
};
