import { request } from '@/request/instance';
var getRecommendPage = function (page, pageSize, type, ordering, ascending) {
    if (ascending === void 0) { ascending = true; }
    return request.get('/recommendation/', {
        params: {
            page: page,
            page_size: pageSize,
            ordering: ascending ? ordering : "-".concat(ordering),
            type: type === 'CAROUSEL' ? 1 : 2,
        },
    });
};
export default { getRecommendPage: getRecommendPage };
